import img1 from '../src/img/img1.webp';
import img2 from '../src/img/img2.webp';
import img3 from '../src/img/img3.webp';
import img4 from '../src/img/img4.webp';

const data = {
  cardData:[
    {
      id:1,
     img: img1,
      title: 'blog 1',
      desc: 'desc 1'
    } , 

    {
      id:2,
      img: img2,
      title: 'blog 2',
      desc: 'desc 2'
    },
    {
      id:3,
      img: img3,
      title: 'blog 3',
      desc: 'desc 3'
    },
    {
      id:4,
      img: img1,
      title: 'blog 4',
      desc: 'desc 4'
    },
    {
      id:5,
      img: img2,
      title: 'blog 5',
      desc: 'desc 5'
    },
    {
      id:6,
      img: img3,
      title: 'blog 6',
      desc: 'desc 6'
    },
    {
      id:7,
      img: img4,
      title: 'blog 7',
      desc: 'desc 7'
    }
  
  ]
}

export default data;