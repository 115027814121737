/* eslint-disable no-unused-vars */
import React from 'react'
import Footersection from '../views/Home/Footersection'
import Navbarsection from '../views/Home/Navbarsection'
 
 

export default function Pthemekit() {
  return (
   <>
    <Navbarsection/>
 
    <section className="text-gray-600 body-font">
  <div className="container px-5 py-24 mx-auto">
    <div className="flex flex-wrap -m-4 text-center">
     <p> Comming Soon </p>
  </div>
  </div>
</section>

   <Footersection/>

  
   </>
  )
}
  