/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React, {useState} from 'react';
import data from '../data';
import Navbarsection from '../views/Home/Navbarsection';
import Footersection from '../views/Home/Footersection';
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";

const App_demo = () => { 
  const [filter, setFilter] = useState(''); 

    const searchText = (event) =>{
      setFilter(event.target.value);
    }
   let dataSearch = data.cardData.filter(item =>{
    return Object.keys(item).some(key => 
    item[key].toString().toLowerCase().includes(filter.toString().toLowerCase())
    )
  });
  return (
    <>
    <Navbarsection/>

 
      <section className='py-4 container'>
     
     <div className='row-justify-content-center'>
     
     <div className='col-12 mb-5'>
     <div className='mb-3 col-4 mx-auto'>
       <h1> Search</h1>
       <input 
       type="text"
       className='from-control'
       value={filter}
       onChange={searchText.bind(this)}
       />
     </div>
       </div>
     
     
     {dataSearch.map((item,index)=>{
       return(

        <><Card className="mt-6 w-96">
           <CardBody>
             <Typography variant="h5" color="blue-gray" className="mb-2">
             {item.title}
             </Typography>
             <Typography>
             {item.desc}
             </Typography>
           </CardBody>
           <CardFooter className="pt-0">
             <Button>Read More</Button>
           </CardFooter>
         </Card>
         
         
         
         <div className='col-11 col-md-6 col-lg-3 mx-0 mb-4'>
             <div className='card p-o overflow-hidden h-100 shadow'>
               <img src="{item.img}" className='card-img-top' />
               <div className='card-body'>
                 <h5 className='card-title'>{item.title}  </h5>
                 <p className='card-text'> {item.desc}  </p>

               </div>
             </div>
           </div></>
       )
     })}
      </div>
           </section>

           <Footersection/>

    </>
   
  )
}

export default App_demo;
 