/* eslint-disable no-unused-vars */
import React from 'react'
import {
  CardBody,
  Typography,
  Button,
} from "@material-tailwind/react";
import { useState, useEffect } from "react";
import 'font-awesome/css/font-awesome.min.css'; 

   
export default function App_development_single() {
  const [size, setSize] = React.useState(null);
 
  const handleOpen = (value) => setSize(value);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4300);
  }, []);
  return (
   <>
   {/*    Start Modal Section Section  */}

    
   <div className="px-4 py-3 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-6 lg:py-15">
      <div className="grid gap-10 lg:grid-cols-2">
        <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
         
          <div className="max-w-xl mb-1">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              App Development 
              <br></br> 
              <span className="text-blue-700">
              At Hickter
              </span>
            </h2>
            <Typography>
        ✔ Build apps for business with  full-stack Level Features With frontend UI, and backends with authentication. <br></br>✔ We Develop App for Both <i style={{fontSize:18}} className="text-green-500 fa-brands fa-android ml-1 p-1"></i> Android & <i style={{fontSize:18}} className="fa-brands text-blue-700 fa-app-store-ios ml-1 p-1"></i>iOS .
        </Typography> 
          </div>
           
          <CardBody className="bg-white" >
          
          <Typography
          variant="h1"
          color="black"
          className="mt-1 flex justify-center gap-1 text-7xl font-normal"><span className="mb-3 text-orange-400 font-bold self-end text-[14px]"> Start <br></br>From  </span> 
          <span className="mt-0 text-[32px]"> ₹14,000 </span>{""}
          <span className="self-end text-[14px]"> 
          / OneTime </span> 

        </Typography><br></br>
        <div
    className="relative grid select-none items-center whitespace-nowrap rounded-lg bg-teal-900 py-3 px-3 font-sans text-xs uppercase text-white">
    <span className=""> * ₹6000 / Yearly Maintainance Charge </span>
  </div> 
  
      </CardBody>
          <div className='ml-[21px]'>
          <a href="/App_development__apply">  
          <Button className="bg-blue-500" >
              <span className="cursor-pointer rounded-full  ">
            <i style={{fontSize:18}} className="fa-solid fa-check-double ml-4 p-2"></i>
            </span>  Submit Your Request
              <svg
                className="inline-block w-3 ml-2"
                fill="currentColor"
                viewBox="0 0 12 12"
              >
                <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
              </svg>  
            </Button></a>
          </div>

        </div>
        <div className="flex items-center justify-center -mx-4 lg:pl-8">
          <div className="flex flex-col items-end px-3">
            <img
              className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
              src="https://ik.imagekit.io/hickterapp/Hickter.com/Banners/e.webp?updatedAt=1714375047446?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
              alt=""
            />
            <img
              className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
              src="https://ik.imagekit.io/hickterapp/Hickter.com/Banners/4.webp?updatedAt=1714375256753?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
              alt=""
            />
          </div>
          <div className="px-3">
            <img
              className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
              src="https://ik.imagekit.io/hickterapp/Hickter.com/Banners/3.webp?updatedAt=1714375256784?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;w=500"
              alt=""
            /><br></br><Typography className="text-center">

            <div className="flex gap-4">
    
          <div className="bg-teal-800 auto text-center p-2 mt-2 mb-1 text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
         
                <i style={{fontSize:35}} className="fab fa-react"></i>
                <p className="text-[13px]" > React </p>
          </div> 
    
          <div className="bg-teal-800 auto text-center p-2 mt-2 mb-1 text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
          <i style={{fontSize:35}} className="fab fa-java"></i>
          
         <p className="text-[13px]" > Java </p>
    </div> 
    
    <div className="bg-teal-800 auto text-center p-2 mt-2 mb-1 text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
          <i style={{fontSize:35}} className="fab fa-swift"></i>
          
         <p className="text-[13px]" > Swift </p>
    </div> 
    
     
    <div className="bg-teal-800 auto p-2 mt-2 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
          <img className="text-center p-2 h-10 w-9"
              src="https://ik.imagekit.io/hickterapp/Hickter.com/Icons/fl.png?updatedAt=1714374803618"
              alt=""
            /> <p className="text-[13px]" > Flutter  </p>
          </div> 
         
    
     
        </div>
            </Typography>
          </div>
           
        </div>
      </div>
    </div>
   </>
  )
}
 