/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter, 
} from "@material-tailwind/react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React, { useEffect } from "react"
import { useRef } from "react";
  import { 
  Button,
  Typography,
} from "@material-tailwind/react";
import Glide from "@glidejs/glide"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 2000, min: 1000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 2000, min: 900 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
export default function Servicesslide() {
  return (
   <>
   <div class="mx-auto">
      <div  class="mb-[3px] text-gray-600 body-font  ">
      <h3 class="mt-[8px] text-center font-bold lg:text-[18px] sm:text-[15px]"> Explore Our Services !⬇️
      <a target="_blank" href="/Our_Portfolio">  <Button className="bg-blue-500 ml-[25px]" >   View Clients  <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button> </a> </h3>
        </div>
      </div>
   
<Carousel responsive={responsive}  className="z-0 mt-[-8px] overscroll-x-none P-3" >
 
{/* First Slide  */}

 <div className="p-8">  
 <Card className="mt-6 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="./assets/services/app.webp"
          alt="card-image" className="h-full w-full rounded-xl object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography className="mt-[-15px] content-center" variant="h5" color="blue-gray" >
          App Development 
        </Typography>
         
<section className="mt-[6px]">

<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-blue-200  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-black dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">   
<img src="./assets/icons/react.png" class="w-10 h-10 rounded-full"  alt="Rounded avatar" /> React </button>

<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-blue-200  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-black dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">   
<img src="./assets/icons/swift.png" class="w-10 h-10 rounded-full"  alt="Rounded avatar" /> Swift </button>

<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-blue-200  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-black dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">   
<img src="./assets/icons/flutter.webp" class="w-10 h-10 rounded-full"  alt="Rounded avatar" /> Flutter </button>
</section>
 

        <Typography className="mt-[6px]">
        ✔ Build Full-stack Apps For Business 
      <br></br>  ✔ 

        <span className="text-[18px]"> <i style={{fontSize:29}} 
        className="text-green-500 mt-[2px] fa-brands fa-android "></i> 
         <i style={{fontSize:29}} className="mt-[-2px] fa-brands text-blue-700 fa-app-store-ios ml-1 p-1"></i> Android & iOS  </span>
        </Typography>
  
      </CardBody>
      <CardFooter >

<section className="mt-[-33px]"> 
<button type="button" class="text-white bg-gradient-to-r from-blue-600   to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Demo </button>


<button type="button" class="text-white bg-gradient-to-r from-teal-500   to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Pricing </button>


<button type="button" class="text-white bg-gradient-to-r from-green-500   to-green-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-green-500 shadow-lg shadow-green-600/50 dark:shadow-lg dark:shadow-green-600/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Start Project</button>
 
</section>
       
      </CardFooter>
    </Card>


 </div>

{/* 2nd Slide  */}

<div className="p-8">  
 <Card className="mt-6 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="./assets/services/website.webp"
          alt="card-image" className="h-full w-full rounded-xl object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography className="mt-[-15px] content-center" variant="h5" color="blue-gray" >
          Website Development 
        </Typography>
        
         

<section className="mt-[6px]">

<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-blue-200  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-black dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">   
<img src="./assets/icons/wordpress.png" class="w-10 h-10 rounded-full"  alt="Rounded avatar" /> Wordpress </button>

<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-blue-200  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-black dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">   
<img src="./assets/icons/sh.webp" class="w-10 h-10 rounded"  alt="Rounded avatar" /> Shopify </button>

<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-blue-200  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-black dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">   
<img src="./assets/icons/laravel.png" class="w-10 h-10 rounded"  alt="Rounded avatar" /> Flutter </button>
</section>
 

        <Typography className="mt-[6px]">
         ✔ Get: Commerce , Retail, RealEstate Etc. 
       <br></br> ✔ Responsive Dextop, Tablet, Smartphone  

        </Typography>
  
      </CardBody>
      <CardFooter >

      <section className="mt-[-33px]"> 
<button type="button" class="text-white bg-gradient-to-r from-blue-600   to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Demo </button>


<button type="button" class="text-white bg-gradient-to-r from-teal-500   to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Pricing </button>


<button type="button" class="text-white bg-gradient-to-r from-green-500   to-green-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-green-500 shadow-lg shadow-green-600/50 dark:shadow-lg dark:shadow-green-600/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Start Project</button>
 
</section>
       
      </CardFooter>
    </Card>


 </div>

{/* 3rd Slide  */}


<div className="p-8">  
 <Card className="mt-6 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="./assets/services/digitalmarketing.webp"
          alt="card-image" className="h-full w-full rounded-xl object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography className="mt-[-15px] content-center" variant="h5" color="blue-gray" >
        Digital Marketing 
        </Typography>
        
         

<section className="mt-[6px]">

<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-blue-200  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-black dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">   
<img src="./assets/icons/googleads.webp" class="w-10 h-10 rounded-full"  alt="Rounded avatar" /> Google Ads </button>

<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-blue-200  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-black dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">   
<img src="./assets/icons/meta.webp" class="w-10 h-10 rounded"  alt="Rounded avatar" /> Meta Ads </button>

<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-blue-200  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-black dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">   
<img src="./assets/icons/seo.webp" class="w-10 h-10 rounded"  alt="Rounded avatar" /> SEO </button>
</section>
 

        <Typography className="mt-[6px]">
         ✔ Grow Business Sales & Revenue   
       <br></br> ✔ SEO, Social Media , Etc 

        </Typography>
  
      </CardBody>
      <CardFooter >

      <section className="mt-[-33px]"> 
<button type="button" class="text-white bg-gradient-to-r from-blue-600   to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Demo </button>


<button type="button" class="text-white bg-gradient-to-r from-teal-500   to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Pricing </button>


<button type="button" class="text-white bg-gradient-to-r from-green-500   to-green-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-green-500 shadow-lg shadow-green-600/50 dark:shadow-lg dark:shadow-green-600/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Start Project</button>
 
</section>
       
      </CardFooter>
    </Card>


 </div>


{/* 4th Slide  */}

<div className="p-8">  
 <Card className="mt-6 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="./assets/services/banner.webp"
          alt="card-image" className="h-full w-full rounded-xl object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography className="mt-[-15px] content-center" variant="h5" color="blue-gray" >
        Banner Ads | Design 
        </Typography>
        
         

<section className="mt-[6px]">

<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-blue-200  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-black dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">   
<img src="./assets/icons/canva.webp" class="w-10 h-10 rounded-full"  alt="Rounded avatar" /> Canva </button>

<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-blue-200  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-black dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">   
<img src="./assets/icons/ps.webp" class="w-10 h-10 rounded"  alt="Rounded avatar" /> Photoshop </button>

<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-blue-200  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-black dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">   
<img src="./assets/icons/ads.webp" class="w-10 h-10 rounded"  alt="Rounded avatar" /> Ads </button>
</section>
 

        <Typography className="mt-[6px]">
        ✔ Create Banner Ads Design.
       <br></br>✔ Regular Posting of Ads.
 

        </Typography>
  
      </CardBody>
      <CardFooter >

      <section className="mt-[-33px]"> 
<button type="button" class="text-white bg-gradient-to-r from-blue-600   to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Demo </button>


<button type="button" class="text-white bg-gradient-to-r from-teal-500   to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Pricing </button>


<button type="button" class="text-white bg-gradient-to-r from-green-500   to-green-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-green-500 shadow-lg shadow-green-600/50 dark:shadow-lg dark:shadow-green-600/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Start Project</button>
 
</section>
       
      </CardFooter>
    </Card>


 </div>




{/* 6th Slide  */}


 
<div className="p-8">  
 <Card className="mt-6 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="./assets/services/video.webp"
          alt="card-image" className="h-full w-full rounded-xl object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography className="mt-[-15px] content-center" variant="h5" color="blue-gray" >
        Video Ads | Promotion 
        </Typography>
        
         

<section className="mt-[6px]">

<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-blue-200  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-black dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">   
<img src="./assets/icons/videoedit.webp" class="w-10 h-10 rounded-full"  alt="Rounded avatar" /> Editing </button>

<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-blue-200  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-black dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">   
<img src="./assets/icons/videoads.webp" class="w-10 h-10 rounded"  alt="Rounded avatar" /> VideoAds </button>

<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-blue-200  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-black dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">   
<img src="./assets/icons/seo.webp" class="w-10 h-10 rounded"  alt="Rounded avatar" /> Promotional </button>
</section>
 

        <Typography className="mt-[6px]">
        ✔ Promotional Video and Ads. 
       <br></br> ✔ Regular Editing & Marketing.



        </Typography>
  
      </CardBody>
      <CardFooter >

      <section className="mt-[-33px]"> 
<button type="button" class="text-white bg-gradient-to-r from-blue-600   to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Demo </button>


<button type="button" class="text-white bg-gradient-to-r from-teal-500   to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Pricing </button>


<button type="button" class="text-white bg-gradient-to-r from-green-500   to-green-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-green-500 shadow-lg shadow-green-600/50 dark:shadow-lg dark:shadow-green-600/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Start Project</button>
 
</section>
       
      </CardFooter>
    </Card>


 </div>





{/* 6th Slide  */}


 
<div className="p-8">  
 <Card className="mt-6 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="./assets/services/digitalmarketing.webp"
          alt="card-image" className="h-full w-full rounded-xl object-cover"
        />
      </CardHeader>
      <CardBody>
        <Typography className="mt-[-15px] content-center" variant="h5" color="blue-gray" >
     Logo Design | Creation 
        </Typography>
         
<section className="mt-[6px]">

<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-white  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-white dark:focus:ring-white shadow-lg shadow-teal-900/40 dark:shadow-lg dark:shadow-white-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">   
<img src="./assets/icons/apple.webp" class="w-14 h-12 rounded-full"  alt="Rounded avatar" />  </button>


<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-white  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-white dark:focus:ring-white shadow-lg shadow-teal-900/40 dark:shadow-lg dark:shadow-white-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">   
<img src="./assets/icons/md.webp" class="w-14 h-12 rounded-full"  alt="Rounded avatar" />  </button>


<button type="button" class="text-black bg-gradient-to-r from-white via-black-600 to-white  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-white dark:focus:ring-white shadow-lg shadow-teal-900/40 dark:shadow-lg dark:shadow-white-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">   
<img src="./assets/icons/nike.webp" class="w-14 h-12 rounded-full"  alt="Rounded avatar" />  </button>

 
</section>
 

        <Typography className="mt-[6px]">
         ✔ Grow Business Sales & Revenue   
       <br></br> ✔ SEO, Social Media , Etc 

        </Typography>
  
      </CardBody>
      <CardFooter >

      <section className="mt-[-33px]"> 
<button type="button" class="text-white bg-gradient-to-r from-blue-600   to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Demo </button>


<button type="button" class="text-white bg-gradient-to-r from-teal-500   to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Pricing </button>


<button type="button" class="text-white bg-gradient-to-r from-green-500   to-green-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-green-500 shadow-lg shadow-green-600/50 dark:shadow-lg dark:shadow-green-600/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"> Start Project</button>
 
</section>
       
      </CardFooter>
    </Card>


 </div>



 
</Carousel>

   </>
  )
}
 

