/* eslint-disable react/no-unescaped-entities */
// eslint-disable-next-line no-unused-vars
import React from 'react'
 import { useState, useEffect } from "react";
import Skeletonui from '../../Components/Skeletonui'; 
  import emailjs from "emailjs-com";
import { useRef } from "react";
  import {
  Card, 
  Button,
  Typography,
} from "@material-tailwind/react";
 
 /* eslint-disable no-unused-vars */
  export default function Contactsection() {
  const [loading, setLoading] = useState(true);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_tnyvml9",
        "template_2eq08xs",
        form.current,
        "8-wOYuQpEpgZuz5_C"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Thanks For Submission ! We Will Response Soon .");
        },
        (error) => {
          console.log(error.text);
          alert("FAILED...", error);
        }
      );
  }; 
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1100);
  }, []);
  return (
   <>
  
<section className="bg-white">
  <div className="mx-auto max-w-screen-xl px-4 mt-3 p-6">
    <div className="grid grid-cols-1 gap-x-16 gap-y-4 lg:grid-cols-5">
      <div className="lg:col-span-2 lg:py-1">
        <p className="max-w-xl text-center text-[16px]">
        Hickter ¦ Hacker's Of Healing  
        </p>
        <ul> 
        <img
          className="p-1 object-contain w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-[210px]"
          src="https://ik.imagekit.io/hickterapp/Hickter.com/blog/1.png?updatedAt=1714330213205"
          alt=""
        />
 
  <li>
    <a
      href="tel:917606825449"
      className="flex items-center gap-2 border-s-[3px] border-blue-500 bg-blue-50 px-4 py-3 text-blue-700"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z" />
</svg>


      <span className="text-sm font-medium"> Phone :
       <a href="tel:917606825449" className='text-green-600 text-[14px]' > +91 7606825449 </a> </span>
    </a>
  </li>

  <li>
    <a href="#"
      className="flex items-center gap-2 border-s-[3px] border-transparent px-4 py-3 text-gray-500 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
</svg>


      <span className="text-sm font-medium"> 
       <a href="#" className='text-gren-600 text-[14px]' >   <address className="mt-2 not-italic"> Beheramal ,Jharsugdua ,Odisha ,India</address>  </a> </span>
    </a>
  </li>

  <li>
    <a
      href="mailto:contact@dailyle.com"
      className="flex items-center gap-2 border-s-[3px] border-transparent px-4 py-3 text-gray-500 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700"
    >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
</svg>
 
      <span className="text-sm font-medium"> Support Mail  :
       <a href="mailto:contact@hickter.com" className='text-green-600 text-[15px]' > contact@hickter.in</a> </span>
    </a>
  </li>
  
</ul>
      </div>

      <div className="rounded-lg p-[2px] shadow-lg lg:col-span-3 lg:p-1">
     
     {loading ? <Skeletonui /> :  <> <Card color="transparent" shadow={false}>
    
    <form ref={form} onSubmit={sendEmail} className="w-full p-2 mx-auto text-center mt-[-8px] mb-2 max-w-screen-lg sm:w-96">
      <div className="mb-1 flex flex-col gap-6">
      
        {/* <!-- Name  --> */}
        <div className="form-group">
        <div className="w-full">
          
<div className="relative w-full min-w-[200px] h-10">
  <input  
name="name_from" 
id="nameFrom"
size="lg"
type="name" className=" form-control  !border-black focus:!border-transparent form-control  peer w-full h-full  text-blue-gray-700 font-sans font-normal  outline-0 focus:outline-0 disabled:bg-blue-gray-50 :border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px]  focus:border-gray-900"
    placeholder=" " /> <label
    className="flex w-full h-full select-none pointer-events-none absolute left-0 mt-[-9px] font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-0.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] peer-focus:text-teal-500 before:border-blue-gray-200 peer-focus:before:!border-teal-500 after:border-blue-gray-200 peer-focus:after:!border-teal-500"> Your Name  
  </label>
</div>
</div> 
  
          </div>

         {/* <!-- Phone No --> */}
        <div className="form-group">
        <div className="w-full">
          
<div className="relative w-full min-w-[200px] h-10">
  <input 
type="number"
     name="phone_from"
     id="phonefrom" 
    size="lg"
    className="form-control  !border-black focus:!border-transparent peer w-full h-full  text-blue-gray-700 font-sans font-normal  outline-0 focus:outline-0 disabled:bg-blue-gray-50 :border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px]  focus:border-gray-900"
    placeholder=" " /> <label
    className="flex w-full h-full select-none pointer-events-none absolute  mt-[-12px]  left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-0.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] peer-focus:text-teal-500 before:border-blue-gray-200 peer-focus:before:!border-teal-500 after:border-blue-gray-200 peer-focus:after:!border-teal-500"> Phone - ( +91xx )  
  </label>
</div>
</div> 

          </div>


{/* <!-- Email  --> */}
<div className="form-group">
        <div className="w-full">
          
<div className="relative w-full min-w-[200px] h-10">
  <input  
name="name_from" 
id="nameFrom"
size="lg"
type="name" className=" form-control  !border-black focus:!border-transparent   peer w-full h-full  text-blue-gray-700 font-sans font-normal  outline-0 focus:outline-0 disabled:bg-blue-gray-50 :border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px]  focus:border-gray-900"
    placeholder=" " /> <label
    className="flex w-full h-full select-none pointer-events-none absolute mt-[-8px] left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] peer-focus:text-teal-500 before:border-blue-gray-200 peer-focus:before:!border-teal-500 after:border-blue-gray-200 peer-focus:after:!border-teal-500"> Email
  </label>
</div>
</div> 
  
          </div>




        {/* <!-- Message --> */} 
          <div className="relative w-full min-w-[200px] form-group">
            <label htmlFor="email_body"><Typography variant="h6" color="blue-gray" className="mb-3">
          Message
        </Typography></label>
        <textarea
    className="message__box form-control peer h-[70px]  w-full resize-none rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-1 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:resize-none disabled:border-0 disabled:bg-blue-gray-50"
    placeholder=" "  size="lg"
    name="message" id="message"  
    
      rows="5"></textarea>
     
          </div>
        
      </div>
      
      <Button type="submit" className="bg-blue-500 mt-6" fullWidth>
       Submit
      </Button>
     
    </form>
  </Card> </>}
   
     </div>
    </div>
  </div>
</section>
   </>
  )
}
