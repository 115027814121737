/* eslint-disable no-unused-vars */
import React from 'react' 
import {
    CardBody,
   Typography,
  Button, 
} from "@material-tailwind/react";
import {
  List,
  ListItem,
  ListItemPrefix,
  Avatar,
  Card,
 } from "@material-tailwind/react";

import { useState, useEffect } from "react";
 import { Badge } from "@material-tailwind/react";
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'font-awesome/css/font-awesome.min.css'; 
import Navbarsection from '../views/Home/Navbarsection';
import Footersection from '../views/Home/Footersection';
import {
   Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
   import {CheckIcon} from "@heroicons/react/24/outline";

export default function  Website_development_hickter() {
   
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4300);
  }, []);
  
  const [size, setSize] = React.useState(null);
 
  const handleOpen = (value) => setSize(value);
  return (
   <>
    <Navbarsection/>
 


{/*    First Website Developments   */}

    <div className="px-4 py-3 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-6 lg:py-15">
      <div className="grid gap-10 lg:grid-cols-2">
        
      <div className="flex items-center justify-center -mx-4 lg:pl-8">
          <div className="flex flex-col items-end px-3">
            <img
              className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
              src="https://ik.imagekit.io/hickterapp/Hickter.com/Banners/2.webp?updatedAt=1714375487897?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
              alt=""
            />
            <img
              className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
              src="https://ik.imagekit.io/hickterapp/Hickter.com/Banners/1.webp?updatedAt=1714375488412?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
              alt=""
            />
          </div>
          <div className="px-3">
            <img
              className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
              src="https://ik.imagekit.io/hickterapp/Hickter.com/Banners/3.webp?updatedAt=1714375488294?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;w=500"
              alt=""
            /><br></br> <Typography className=" text-center">
            <div className="flex gap-1">
    
    <div className="bg-teal-800 auto text-center  p-2 mt-1 mb-1 text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
    
          <i style={{fontSize:30}} className="fab fa-php"></i>
          <p className="text-[13px]" > php </p>
    </div> 
    
    <div className="bg-teal-800 auto text-center p-2 mt-1 mb-1 text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20
     active:shadow-[#ea4335]/10">
    <i style={{fontSize:30}} className="fab fa-html5"></i>
    
    <p className="text-[13px]" > HTML </p>
    </div> 
    
    <div className="bg-teal-800 auto text-center p-2 mt-1 mb-1 text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
    <i style={{fontSize:26}} className="fa-brands fa-css3-alt"></i>
    
    <p className="text-[13px]" > CSS </p>
    </div> 
     
    <div className="bg-teal-800 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
    <i style={{fontSize:25}} className="fa-brands fa-js"></i><p className="text-[13px]" > JAVASCRIPT  </p>
    </div>  
     
    
    </div>
            </Typography>
          </div>
           
        </div>
       
       
        <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
         
          <div className="max-w-xl mb-1">
            <h2 style={{fontSize:26}}  className="max-w-lg mb-6 font-sans  font-bold tracking-tight text-gray-900 sm:leading-none">
            Website Developments 
              <br></br> 
              <span className="text-orange-600">
              At Hickter
              </span>
            </h2>
            <Typography>
        ✔ Get Retail, RealEstate, Commerce, Hotel, Bakery Etc. Website From Hickter. 
        </Typography>  
      
          </div>
           
          <CardBody className="bg-white" > 
           
        <div className="ml-[-25px] relative grid  select-none items-center whitespace-nowrap rounded-lg bg-teal-900 py-3 px-3 font-sans text-xs uppercase text-white">
    <span className=""> * ₹ 5000 - ₹2 Lac / Monthly Charge </span> 
    

  </div> <br></br>
  <div className="ml-[-25px] relative grid  select-none items-center whitespace-nowrap rounded-lg bg-teal-900 py-3 px-3 font-sans text-xs uppercase text-white">
 
    <span className=""> * ₹6000  / Yearly Charge  </span>

  </div> 
  
      </CardBody>
          <div>
          <a href="/Website_development__apply"> 
          
           <Button 
              className="ml-[4px] text-center flex items-center font-semibold transition-colors duration-200 bg-blue-600 text-white p-3 rounded hover:text-deep-purple-800"
            >
              <span className="cursor-pointer   ">
            <i style={{fontSize:15}} className="fa-solid fa-check-double ml-3 p-2"></i>
            </span> Submit Your Request
              <svg
                className="inline-block w-3 ml-2"
                fill="currentColor"
                viewBox="0 0 12 12"
              >
                <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
              </svg>  
            </Button></a>
          </div>

        </div>

 
      
      </div>
    </div>
<br></br><br></br>
  {/*    End 1st Section  */}

  {/*    Body Site Type   */}

  <section className="text-gray-600 body-font">
  <div className="container px-2 py-2 mx-auto">
    <div className="flex flex-wrap -m-2 text-left">

      <div className="p-4 sm:w-1/2 w-1/2 text-left">
      <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
         
         <div className="max-w-xl mb-1">
           <h2 style={{fontSize:15}} className="max-w-lg mb-4 font-sans font-bold tracking-tight text-gray-900   sm:leading-none">
           Normal Template Website 
           </h2>
           <Badge
      content={<CheckIcon className="h-4 w-4 text-white" strokeWidth={2.5} />}
      className="bg-gradient-to-tr from-green-400 to-green-600 border-2 border-white shadow-lg shadow-black/20"
    >
      <Button style={{fontSize:10}} className="bg-blue-500" onClick={() => handleOpen("xl")} variant="gradient" > Benefits </Button>
    </Badge>
           
      <Dialog
        open={
          size === "xl"
        }
        size={size || "xl"}
        handler={handleOpen}
      >
        <DialogHeader> Benefits You are Getting !<DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => handleOpen(null)}
            className="mr-1"
          >
            <span> Cancel </span>
          </Button>
         
        </DialogFooter> </DialogHeader>
        <DialogBody>
          
        <Card className="w-400">
      <List>
        <ListItem>
          <ListItemPrefix>
            <Avatar variant="circular" alt="candice" src="../assets/icons/feature.png" />
          </ListItemPrefix>
          <div>
            <Typography variant="h6" color="blue-gray">
             Features & Benefits 
            </Typography>
            <Typography variant="small" color="gray" className="font-normal">
            WordPress version updates
            </Typography> 
            <Typography variant="small" color="gray" className="font-normal">
            SEO Ranking
            </Typography>
            
            <Typography variant="small" color="gray" className="font-normal">
            Daily Full Site Backup
            </Typography>


            <Typography variant="small" color="gray" className="font-normal">
            Plugin & Theme Update
            </Typography>
 

            <Typography variant="small" color="gray" className="font-normal">
            WordPress Core Update
            </Typography>


            <Typography variant="small" color="gray" className="font-normal">
            Performance & Speed Check
            </Typography>

            <Typography variant="small" color="gray" className="font-normal">
           Google Analytics Traffic Stats
            </Typography>

            <Typography variant="small" color="gray" className="font-normal">
            Uptime Monitoring
            </Typography>

            <Typography variant="small" color="gray" className="font-normal">
            Broken Link Monitoring
            </Typography>

            <Typography variant="small" color="gray" className="font-normal">
            Personalised Monthly Report
            </Typography>

            <Typography variant="small" color="gray" className="font-normal">
            Woocommerce Sales Stats
            </Typography>

            <Typography variant="small" color="gray" className="font-normal">
            Google Analytics Traffic Stats
            </Typography>

          </div>
        </ListItem>


        <ListItem>
          <ListItemPrefix>
            <Avatar variant="circular" alt="alexander" src="../assets/icons/free.png" />
          </ListItemPrefix>
          <div>
            <Typography variant="h6" color="blue-gray">
              Free Things
            </Typography>
            <Typography variant="small" color="gray" className="font-normal">
            Free Domain & Hosting 
            </Typography>

            <Typography variant="small" color="gray" className="font-normal">
           Free 24x7 Support 
            </Typography>


          </div>
        </ListItem>
        
      </List>
    </Card>
        

        </DialogBody>
        
      </Dialog>
           <Typography style={{fontSize:13}}>
           <br></br>  ✔ Tech Stack 
       </Typography> 
        
<div className="grid grid-cols-2 md:grid-cols-3 gap-[-50]">
 
 
    <div>
    <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center "   style={{fontSize:13}}>    
       <img src="./assets/icons/HTML.png" className="w-5 h-5"  alt="Rounded avatar" /> HTML </button> 
 </div>


    <div>
    <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
       <img src="./assets/icons/CSS.png" className="w-5 h-5"  alt="Rounded avatar"  /> CSS </button>
        
    </div>
 

    <div>
    <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center "   style={{fontSize:13}}>    
       <img src="./assets/icons/JS.png" className="w-5 h-5 "  alt="Rounded avatar" /> JS </button>
 
    </div>


    <div>
    <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}} >    
       <img src="./assets/icons/wordpress.png" className="w-5 h-5 "  alt="Rounded avatar" /> Wordpress </button>
    </div>


    <div>
    <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
       <img src="./assets/icons/ELE.png" className="w-5 h-5 "  alt="Rounded avatar" /> Elementor </button>
 
    </div> 
    </div> 
         </div>
          
         <CardBody className="bg-white" > 
         <Typography
         variant="h1"
         color="black"
         className="mb-4 flex justify-left gap-1 text-7xl font-normal"><span className="mb-3 ml-[-25px] text-orange-400 font-bold self-end"   style={{fontSize:13}} > Start <br></br>From  </span> 
         <span   style={{fontSize:16}}  className="mt-0"> ₹7000  </span>{""}
         <span style={{fontSize:12}}  > / OneTime </span> 

       </Typography> 
       <div
   className="mt-2  ml-[-25px] relative grid  select-none items-left whitespace-nowrap rounded-lg text-black bg-red-200 py-1 px-1 
   font-sans text-xs uppercase" style={{fontSize:9}}>
   <span className="text-left"> *  bg-red-5 Including <br></br> Free Domain & Hosting   <br></br> <br></br> ₹6000 / Yearly  </span>
 </div> 
  
 
     </CardBody>
          
       </div>

      </div> 
      <div className="p-4 sm:w-1/2 w-1/2 text-left">
      <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
         
         <div className="max-w-xl mb-1">
           <h2  style={{fontSize:15}} className="max-w-lg mb-4 font-sans font-bold tracking-tight text-gray-900 sm:text-sm sm:leading-none">
           Single Page / Landing Website 
              
           </h2>
           <Badge
      content={<CheckIcon className="h-4 w-4 text-white" strokeWidth={2.5} />}
      className="bg-gradient-to-tr from-green-400 to-green-600 border-2 border-white shadow-lg shadow-black/20"
    >
      <Button style={{fontSize:10}} className="bg-blue-500" onClick={() => handleOpen("xl")} variant="gradient" > Benefits </Button>
    </Badge>
           
           <Typography style={{fontSize:13}}>
           <br></br> ✔ Tech Stack 
       </Typography> 
       
       <div className="grid grid-cols-2 md:grid-cols-3 gap-[-50]">
 
 <div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
    <img src="./assets/icons/HTML.png" className="w-5 h-5 "  alt="Rounded avatar" /> HTML </button>
     
</div>


 <div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
    <img src="./assets/icons/CSS.png" className="w-5 h-5 "  alt="Rounded avatar" /> CSS </button>
     
 </div>


 <div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center" style={{fontSize:11}}>    
    <img src="./assets/icons/JS.png" className="w-5 h-5 "  alt="Rounded avatar" /> JS </button>

 </div>
 
 <div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
    <img src="./assets/icons/wordpress.png" className="w-5 h-5 "  alt="Rounded avatar" /> Wordpress </button>
 </div>
 
 <div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
    <img src="./assets/icons/TCSS.png" className="w-5 h-5 "  alt="Rounded avatar" /> TailwindCSS </button>

 </div>



 </div>
         </div>
          
         <CardBody className="bg-white" > 
         <Typography
         variant="h1"
         color="black"
         className="mb-4 flex justify-left gap-1 text-7xl font-normal"><span className="mb-3 ml-[-25px] text-orange-400 font-bold self-end "   style={{fontSize:13}} > Start <br></br>From  </span> 
          <span  style={{fontSize:16}}  className="mt-0"> ₹5000 </span>{""}
          <span style={{fontSize:10}}  > / OneTime </span> 
 
       </Typography> 
       <div
   className="mt-2  ml-[-25px] relative grid  select-none items-left whitespace-nowrap rounded-lg text-black bg-red-200 py-1 px-1 
   font-sans text-xs uppercase" style={{fontSize:9}}>
   <span className="text-left"> * bg-red-5 Including <br></br> Free Domain & Hosting   <br></br> <br></br> ₹4000 / Yearly </span>
 </div> 
  
     </CardBody>
          
       </div>

      </div>
 
    </div>
  </div>
</section>

   
 {/*    2nd Site Type   */}

<section className="text-gray-600 body-font">
  <div className="container px-2 py-2 mx-auto">
    <div className="flex flex-wrap -m-2 text-left">

      <div className="p-4 sm:w-1/2 w-1/2 text-left">
      <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
         
         <div className="max-w-xl mb-1">
           <h2 style={{fontSize:15}}   className="max-w-lg mb-4 font-sans font-bold tracking-tight text-gray-900   sm:leading-none">
           Multipage Custom Website 
           </h2>

           <Badge
      content={<CheckIcon className="h-4 w-4 text-white" strokeWidth={2.5} />}
      className="bg-gradient-to-tr from-green-400 to-green-600 border-2 border-white shadow-lg shadow-black/20"
    >
      <Button style={{fontSize:12}} className="bg-blue-500" onClick={() => handleOpen("xl")} variant="gradient" > Benefits </Button>
    </Badge>

           <Typography style={{fontSize:13}}>
     <br></br>  ✔ Tech Stack 
       </Typography> 
       <div className="grid grid-cols-2 md:grid-cols-3 gap-[-50]">
 
 <div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
    <img src="./assets/icons/HTML.png" className="w-5 h-5 "  alt="Rounded avatar" /> HTML </button>
     
</div>
 
 <div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
    <img src="./assets/icons/CSS.png" className="w-5 h-5 "  alt="Rounded avatar" /> CSS </button>
     
 </div>


 <div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center" style={{fontSize:11}}>    
    <img src="./assets/icons/JS.png" className="w-5 h-5 "  alt="Rounded avatar" /> JS </button>

 </div>
 
 <div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
    <img src="./assets/icons/wordpress.png" className="w-5 h-5 "  alt="Rounded avatar" /> Wordpress </button>
 </div>


 <div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
    <img src="./assets/icons/TCSS.png" className="w-5 h-5 "  alt="Rounded avatar" /> TailwindCSS </button>

 </div> </div> </div>
          
         <CardBody className="bg-white" > 
         <Typography
         variant="h1"
         color="black"
         className="mb-4 flex justify-left gap-1 text-7xl font-normal"><span className="mb-3 ml-[-25px] text-orange-400 font-bold self-end "   style={{fontSize:13}}> Start <br></br>From  </span> 
         <span style={{fontSize:16}}className="mt-0 "> ₹12000 </span>{""}
         <span className="" style={{fontSize:10}} > / OneTime </span> 

       </Typography> 
       <div
   className="mt-2  ml-[-25px] relative grid  select-none items-left whitespace-nowrap rounded-lg text-black bg-red-200 py-1 px-1 
   font-sans text-xs uppercase" style={{fontSize:9}}>
   <span className="text-left"> * bg-red-5 Including <br></br> Free Domain & Hosting   <br></br> <br></br> ₹11000 / Yearly  </span>
 </div> 
  
     </CardBody>
          
       </div>

      </div> 
 
      <div className="p-4 sm:w-1/2 w-1/2 text-left">
      <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
         
         <div className="max-w-xl mb-1">
           <h2 style={{fontSize:15}}  className="max-w-lg mb-4 font-sans font-bold tracking-tight text-gray-900 sm:text-sm sm:leading-none">
           Ecommerce ( Single Vendor ) 
           </h2>
           <Badge
      content={<CheckIcon className="h-4 w-4 text-white" strokeWidth={2.5} />}
      className="bg-gradient-to-tr from-green-400 to-green-600 border-2 border-white shadow-lg shadow-black/20"
    >
      <Button style={{fontSize:10}} className="bg-blue-500" onClick={() => handleOpen("xl")} variant="gradient" > Benefits </Button>
    </Badge>

           <Typography style={{fontSize:13}}>
      <br></br> ✔ Tech Stack 
       </Typography> 
       <div className="grid grid-cols-2 md:grid-cols-3 gap-[-50]">
  
 
<div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center "   style={{fontSize:13}}>    
    <img src="./assets/icons/HTML.png" className="w-5 h-5"  alt="Rounded avatar" /> HTML </button> 
</div>

<div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center "   style={{fontSize:13}}>    
    <img src="./assets/icons/CSS.png" className="w-5 h-5"  alt="Rounded avatar" /> CSS </button> 
</div>

<div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center "   style={{fontSize:13}}>    
    <img src="./assets/icons/JS.png" className="w-5 h-5"  alt="Rounded avatar" /> JS </button> 
</div>

<div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center "   style={{fontSize:13}}>    
    <img src="./assets/icons/Shopify.png" className="w-5 h-5"  alt="Rounded avatar" /> Shopify </button> 
</div>

<div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center "   style={{fontSize:13}}>    
    <img src="./assets/icons/wordpress.png" className="w-5 h-5"  alt="Rounded avatar" /> Wordpress </button> 
</div><div> </div> </div>
   </div>
          
         <CardBody className="bg-white" > 
         <Typography
         variant="h1"
         color="black"
         className="mb-4 flex justify-left gap-1 text-7xl font-normal"><span className="mb-3 ml-[-27px] text-orange-400 font-bold self-end"   style={{fontSize:13}}> Start <br></br>From  </span> 
         <span className="mt-0 "  style={{fontSize:17}}>₹15000  </span>{""}
         <span style={{fontSize:12}}> / OneTime </span> 

       </Typography> 
       <div
   className="mt-2  ml-[-25px] relative grid  select-none items-left whitespace-nowrap rounded-lg text-black bg-red-200 py-1 px-1 
   font-sans text-xs uppercase" style={{fontSize:9}}>
   <span className="text-left">  * bg-red-5 Including <br></br> Free Domain & Hosting   <br></br> <br></br> ₹14000 / Yearly  </span>
 </div> 
  
     </CardBody>
          
       </div>

      </div>
 
    </div>
  </div>
</section>


{/*    3rd Site Type   */}

<section className="text-gray-600 body-font">
  <div className="container px-2 py-2 mx-auto">
    <div className="flex flex-wrap -m-2 text-left">

      <div className="p-4 sm:w-1/2 w-1/2 text-left">
      <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
         
         <div className="max-w-xl mb-1">
           <h2 style={{fontSize:15}}  className="max-w-lg mb-4 font-sans font-bold tracking-tight text-gray-900   sm:leading-none">
           Ecommerce  ( Multi Vendor )
           </h2>

           <Badge
      content={<CheckIcon className="h-4 w-4 text-white" strokeWidth={2.5} />}
      className="bg-gradient-to-tr from-green-400 to-green-600 border-2 border-white shadow-lg shadow-black/20"
    >
      <Button style={{fontSize:10}} className="bg-blue-500" onClick={() => handleOpen("xl")} variant="gradient" > Benefits </Button>
    </Badge>


           <Typography style={{fontSize:13}}>
    <br></br>   ✔ Tech Stack 
       </Typography> 
       <div className="grid grid-cols-2 md:grid-cols-3 gap-[-50]">
  
<div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center "   style={{fontSize:13}}>    
    <img src="./assets/icons/HTML.png" className="w-5 h-5"  alt="Rounded avatar" /> HTML </button> 
</div>

<div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center "   style={{fontSize:13}}>    
    <img src="./assets/icons/CSS.png" className="w-5 h-5"  alt="Rounded avatar" /> CSS </button> 
</div>

<div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center "   style={{fontSize:13}}>    
    <img src="./assets/icons/JS.png" className="w-5 h-5"  alt="Rounded avatar" /> JS </button> 
</div>

<div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center "   style={{fontSize:13}}>    
    <img src="./assets/icons/Shopify.png" className="w-5 h-5"  alt="Rounded avatar" /> Shopify </button> 
</div>

<div>
 <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
    me-1 mb-2 flex justify-center items-center "   style={{fontSize:13}}>    
    <img src="./assets/icons/wordpress.png" className="w-5 h-5"  alt="Rounded avatar" /> Wordpress </button> 
</div> <div> </div></div>
 
         </div>
          
         <CardBody className="bg-white" > 
         <Typography
         variant="h1"
         color="black"
         className="mb-4 flex justify-left gap-1 text-7xl font-normal"><span className="mb-3 ml-[-25px] text-orange-400 font-bold self-end"   style={{fontSize:13}}> Start <br></br>From  </span> 
         <span   style={{fontSize:16}}>₹25000  </span>{""}
         <span className="" style={{fontSize:10}}> / OneTime </span> 

       </Typography> 
       <div
   className="mt-2  ml-[-25px] relative grid  select-none items-left whitespace-nowrap rounded-lg text-black bg-red-200 py-1 px-1 
   font-sans text-xs uppercase" style={{fontSize:9}}>
   <span className="text-left">* bg-red-5 Including <br></br> Free Domain & Hosting   <br></br> <br></br> ₹24000 / Yearly </span>
 </div>
  
 
     </CardBody>
          
       </div>

      </div> 
      <div className="p-4 sm:w-1/2 w-1/2 text-left">
      <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
         
         <div className="max-w-xl mb-1">
           <h2 style={{fontSize:15}}  className="max-w-lg mb-4 font-sans font-bold tracking-tight text-gray-900 sm:text-sm sm:leading-none">
           Advance Fullstack Website 
            
           </h2>
           <Badge
      content={<CheckIcon className="h-4 w-4 text-white" strokeWidth={2.5} />}
      className="bg-gradient-to-tr from-green-400 to-green-600 border-2 border-white shadow-lg shadow-black/20"
    >
      <Button style={{fontSize:10}} className="bg-blue-500" onClick={() => handleOpen("xl")} variant="gradient" > Benefits </Button>
    </Badge>

           <Typography style={{fontSize:13}}>
      <br></br> ✔ Tech Stack 
       </Typography> 
       <div className="grid grid-cols-2 md:grid-cols-3 gap-[-50]">
  
 
  <div>
   <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
      me-1 mb-2 flex justify-center items-center "   style={{fontSize:13}}>    
      <img src="./assets/icons/nodejs.png" className="w-5 h-5"  alt="Rounded avatar" /> Nodejs  </button> 
  </div>
  
  <div>
   <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
      me-1 mb-2 flex justify-center items-center "   style={{fontSize:13}}>    
      <img src="./assets/icons/FIRE.png" className="w-5 h-5"  alt="Rounded avatar" /> Firebase  </button> 
  </div>
  
  <div>
   <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
      me-1 mb-2 flex justify-center items-center "   style={{fontSize:13}}>    
      <img src="./assets/icons/react.png" className="w-5 h-5"  alt="Rounded avatar" /> React  </button> 
  </div>
  
  <div>
   <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
      me-1 mb-2 flex justify-center items-center "   style={{fontSize:13}}>    
      <img src="./assets/icons/flutter.webp" className="w-5 h-5"  alt="Rounded avatar" /> Flutter  </button> 
  </div>
    
  <div>
   <button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
      me-1 mb-2 flex justify-center items-center "   style={{fontSize:13}}>    
      <img src="./assets/icons/php.png" className="w-5 h-5"  alt="Rounded avatar" /> php </button> 
  </div>
  
  <div>
  
  </div> 
  </div>  </div>
      
          
         <CardBody className="bg-white" > 
         <Typography
         variant="h1"
         color="black"
         className="mb-4 flex justify-left gap-1 text-7xl font-normal"><span className="mb-3 ml-[-25px] text-orange-400 font-bold self-end text-[12px]"> Start <br></br>From  </span> 
         <span   style={{fontSize:16}}> ₹30,000  </span>{""}
         <span style={{fontSize:10}}> / OneTime </span> 

       </Typography> 

       <div
   className="mt-2  ml-[-25px] relative grid  select-none items-left whitespace-nowrap rounded-lg text-black bg-red-200 py-1 px-1 
   font-sans text-xs uppercase" style={{fontSize:9}}>
   <span className="text-left">* bg-red Including <br></br> Free Domain & Hosting   <br></br> <br></br> ₹29000 / Yearly </span>
 </div> 
 
     </CardBody>
          
       </div>

      </div>
 
    </div>
  </div>
</section>


{/*    4th Site Type   */}

<section className="text-gray-600 body-font">
  <div className="container px-2 py-2 mx-auto">
    <div className="flex flex-wrap -m-2 text-left">

      <div className="p-4 sm:w-1/2 w-1/2 text-left">
      <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
         
         <div className="max-w-xl mb-1">
           <h2 style={{fontSize:15}}  className="max-w-lg mb-4 font-sans font-bold tracking-tight text-gray-900   sm:leading-none">
           Advance Ecommerce <br></br>( Single Vendor ) 
              
           </h2>
           <Badge
      content={<CheckIcon className="h-4 w-4 text-white" strokeWidth={2.5} />}
      className="bg-gradient-to-tr from-green-400 to-green-600 border-2 border-white shadow-lg shadow-black/20"
    >
      <Button style={{fontSize:10}} className="bg-blue-500" onClick={() => handleOpen("xl")} variant="gradient" > Benefits </Button>
    </Badge>
    
           <Typography style={{fontSize:13}}>
      <br></br> ✔ Tech Stack 
       </Typography> 
       <div className="grid grid-cols-2 md:grid-cols-3 gap-[-50px]">

<div>
<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
       <img src="./assets/icons/nodejs.png" className="w-5 h-5 "  alt="Rounded avatar" /> Nodejs </button>
 
</div> 


<div>
<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
       <img src="./assets/icons/nett.png" className="w-5 h-5 "  alt="Rounded avatar" /> .Net </button>
   
</div> 


<div>
<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
       <img src="./assets/icons/react.png" className="w-5 h-5 "  alt="Rounded avatar" /> React </button>
     
</div>



<div>
<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
       <img src="./assets/icons/flutter.webp" className="w-5 h-5 "  alt="Rounded avatar" /> Flutter </button>
   
</div>


<div>
  
<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
       <img src="./assets/icons/php.png" className="w-5 h-5 "  alt="Rounded avatar" /> Php </button>
   
</div>


<div>
  
<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-2 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
       <img src="./assets/icons/laravel.png" className="w-5 h-5 "  alt="Rounded avatar" /> Laravel </button>
     

</div>
 
</div>
 
 
         </div>
          
         <CardBody className="bg-white" > 
         <Typography
         variant="h1"
         color="black"
         className="mb-4 flex justify-left gap-1 text-7xl font-normal"><span className="mb-3 ml-[-25px] text-orange-400 font-bold self-end text-[12px]"> Start <br></br>From  </span> 
         <span   style={{fontSize:16}}>₹35,000 </span>{""}
         <span className="" style={{fontSize:10}}> / OneTime </span> 

       </Typography> 
       <div
   className="mt-2 ml-[-25px] relative grid  select-none items-left whitespace-nowrap rounded-lg bg-red-200 py-1 px-1 
   font-sans text-xs uppercase text-black" style={{fontSize:9}}>
   <span className="text-left " > * bg-red-5 Including <br></br> Free Domain & Hosting   <br></br> <br></br> ₹34000 / Yearly </span>
 </div> 
 
     </CardBody>
          
       </div>

      </div> 
      <div className="p-4 sm:w-1/2 w-1/2 text-left">
      <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
         
         <div className="max-w-xl mb-1">
           <h2  style={{fontSize:15}}  className="max-w-lg mb-4 font-sans font-bold tracking-tight text-gray-900 sm:text-sm sm:leading-none">
           Advance Ecommerce <br></br>( Multi Vendor )
            
           </h2>

           <Badge
      content={<CheckIcon className="h-4 w-4 text-white" strokeWidth={2.5} />}
      className="bg-gradient-to-tr from-green-400 to-green-600 border-2 border-white shadow-lg shadow-black/20"
    >
      <Button style={{fontSize:10}} className="bg-blue-500" onClick={() => handleOpen("xl")} variant="gradient" > Benefits </Button>
    </Badge>

           <Typography style={{fontSize:13}}>
     <br></br>  ✔ Tech Stack 
       </Typography> 
       <div className="grid grid-cols-2 md:grid-cols-3 gap-[-50px]">

<div>
<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
       <img src="./assets/icons/nodejs.png" className="w-5 h-5 "  alt="Rounded avatar" /> Nodejs </button>
 
</div> 


<div>
<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
       <img src="./assets/icons/nett.png" className="w-5 h-5 "  alt="Rounded avatar" /> .Net </button>
   
</div> 


<div>
<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
       <img src="./assets/icons/react.png" className="w-5 h-5 "  alt="Rounded avatar" /> React </button>
     
</div>
 
<div>
<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
       <img src="./assets/icons/flutter.webp" className="w-5 h-5 "  alt="Rounded avatar" /> Flutter </button>
   
</div>


<div>
  
<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-1 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
       <img src="./assets/icons/php.png" className="w-5 h-5 "  alt="Rounded avatar" /> Php </button>
   
</div>


<div>
  
<button type="button" className="text-black bg-gradient-to-r from-white via-black-600 to-blue-100  hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-black dark:focus:ring-blue-100 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-4 py-2 text-center 
       me-2 mb-2 flex justify-center items-center"   style={{fontSize:13}}>    
       <img src="./assets/icons/laravel.png" className="w-5 h-5 "  alt="Rounded avatar" /> Laravel </button>
     

</div>
 
</div>
  
         </div>
          
          
         <CardBody className="bg-white" > 
         <Typography
         variant="h1"
         color="black"
         className=" mb-4 flex justify-left gap-1 text-7xl font-normal"><span className=" mb-3 ml-[-25px] text-orange-400 font-bold self-end text-[12px]"> Start <br></br>From  </span> 
         <span  style={{fontSize:17}} className="mt-0"> ₹80,000    </span>{""}
         <span className=""  style={{fontSize:12}}> / OneTime </span>  
       </Typography> 
       <div
   className="mt-2  ml-[-25px] relative grid  select-none items-left whitespace-nowrap rounded-lg text-black bg-red-200 py-1 px-1 
   font-sans text-xs uppercase" style={{fontSize:9}}>
   <span className="text-left">  * bg-red-5 Including <br></br> Free Domain & Hosting   <br></br> <br></br> ₹79000 / Yearly  </span>
 </div> 
 
     </CardBody>
          
       </div>

      </div>
 
    </div>
  </div>
</section>



    <Footersection/>
    </>
  )
}
 

 