/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
 


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React, { useEffect } from "react"
import Glide from "@glidejs/glide"
import Navbarsection from "../views/Home/Navbarsection";
import Footersection from "../views/Home/Footersection";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 2000, min: 1000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 2000, min: 900 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items:1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
// eslint-disable-next-line react-refresh/only-export-components
function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-3 w-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );
}
export default function Portfolio_section_hickter() {
  return (
   <>
   <section className="mt-[4px]">
   <div class="mx-auto">
      <div  class="mt-[6px] text-gray-600 body-font  ">
      <h3 class="text-center lg:text-[22px] font-bold sm:text-2xl"> Our Portfolio   <a href="/Our_Portfolio">  <Button className="bg-blue-600">   View All  <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button> </a> </h3>
        </div>
      </div>
 

      <Carousel responsive={responsive}  className="z-[0] overscroll-x-none P-4" >
    {/* End Portfolio 0   */}

      <div className="p-1">
   <Card className="mb-[30px]  p-[4px] mt-0 w-90">
         <CardHeader color="blue-gray" className="relative h-56">
           <img src="https://ik.imagekit.io/hickterapp/Hickter.com/Portfolio/nv.webp?updatedAt=1714328988685"
             alt="New Vatika | Clothing Store Brand " />
         </CardHeader>
   
         <CardBody className="mb-[-22px] ">
           <Typography variant="h5" color="blue-gray" className="mb-0">
           New Vatika | Clothing Store Brand 
           </Typography>
           <Typography>
           Clothing brand stores offer a unique shopping experience that is unlike any other. These stores typically sell a variety of clothing items from a single brand.
            </Typography>
         </CardBody>
   
         <div className="group mb-[-2px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
      
               <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:30}} class="fab fa-php"></i>
               </span>
          
             <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:30}} class="fab fa-wordpress"></i>
               </span>
         
             <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:30}} class="fab fa-html5"></i>
               </span>
            
             
             <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:30}} class="fab fa-css3"></i>
               </span>
            
           </div>
         <CardFooter className="mt-[-16px]">
          <a href="https://newvatika.in/" target="_blank"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button></a> 
         </CardFooter>
       </Card>
   {/* End Portfolio 0   */}
   </div>
 
 
  {/* Start Portfolio 2  */}
<div className="p-1">
  
<Card className="mb-[30px]  p-[4px] mt-0 w-90">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://ik.imagekit.io/hickterapp/Hickter.com/Portfolio/Auragoa.png?updatedAt=1714328978324"
          alt="Auragoa | SPA & Living

          "
        />
      </CardHeader>

      <CardBody className="mb-[-22px] ">
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Auragoa | SPA & Living 
        </Typography>
        <Typography>
        This wellness oasis is an hour and a half’s drive from Goa International Airport.  Arambol beach, also very close to the property options Available . 
         
         </Typography>
      </CardBody>

      <div className="group mb-[-2px] ml-5  inline-flex flex-wrap items-center  gap-7"> 

   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-wordpress"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-css3"></i>
            </span>
         
        </div>
        <CardFooter className="mt-[-16px]">
       <a href="https://auragoa.com/" target="_blank"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
</div>
 {/* End  Portfolio 2  */}

  {/* Start Portfolio 3  */}
<div className="p-1">
  
<Card className="mb-[30px]  p-[4px] mt-0 w-90">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://ik.imagekit.io/hickterapp/Hickter.com/Portfolio/voxtron.png?updatedAt=1714328996866"
          alt="Voxtron | Digital Solution"
        />
      </CardHeader>

      <CardBody className="mb-[-22px] ">
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Voxtron | Digital Solution 
        </Typography>

        <Typography>
        Application development company with 20+ years of experience, we build highly secure, scalable and robust applications for global businesses.  
         
         </Typography>
      </CardBody>

      <div className="group mb-[-2px] ml-5  inline-flex flex-wrap items-center  gap-7"> 


   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fa-brands fa-js"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-wordpress"></i>
            </span>
         
        </div>
        <CardFooter className="mt-[-16px]">
       <a href="https://www.voxtronme.com/" target="_blank"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
</div>
 {/* End  Portfolio 3  */}
 
  {/* Start Portfolio 4  */}
<div className="p-1">
  
  
 
<Card className="mb-[30px]  p-[4px] mt-0 w-90">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://ik.imagekit.io/hickterapp/Hickter.com/Portfolio/Isyx%20Tech.png?updatedAt=1714328983529"
          alt="ISYX Tech – Tech Solution 
          "
        />
      </CardHeader>

      <CardBody className="mb-[-22px] ">
        <Typography variant="h5" color="blue-gray" className="mb-2">
        ISYX Tech – Tech Solution
  
        </Typography>
        <Typography>
        The company was founded in 2011 . 10+ Global Offices, we help businesses to  impact on their customers by designing, implementing and managing infrastructure. 
         
         </Typography>
      </CardBody>

      <div className="group mb-[-2px] ml-5  inline-flex flex-wrap items-center  gap-7"> 

   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-wordpress"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-css3"></i>
            </span>
         
        </div>
        <CardFooter className="mt-[-16px]">
       <a href="https://isyxtech.com/" target="_blank"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
</div>
 {/* End  Portfolio 4  */}

 {/* Start Portfolio 5  */}
 <div className="p-1">
  
  
 
 <Card className="mb-[30px]  p-[4px] mt-0 w-90">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://ik.imagekit.io/hickterapp/Hickter.com/Portfolio/fiji.png?updatedAt=1714328979203"
          alt="Fiji Water – Brand Site 
          "
        />
      </CardHeader>

      <CardBody className="mb-[-22px] ">
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Fiji Water – Brand Site 
 
        </Typography>
        <Typography>
        FIJI Water has been committed to doing business responsibly and seeking opportunities to make a difference. Local communities 
         </Typography>
      </CardBody>

      <div className="group mb-[-2px] ml-5  inline-flex flex-wrap items-center  gap-7"> 


   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-laravel"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fa-brands fa-js"></i>
            </span>
         
        </div>
        <CardFooter className="mt-[-16px]">
       <a href="https://www.fijiwater.com/" target="_blank"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
</div>
 {/* End  Portfolio 5  */}

 {/* Start Portfolio 6  */}
 <div className="p-1">
  
  
 
 <Card className="mb-[30px]  p-[4px] mt-0 w-90">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://ik.imagekit.io/hickterapp/Hickter.com/Portfolio/wt.webp?updatedAt=1714328947026"
          alt=" WorkThere – Co-Working Space 
          "
        />
      </CardHeader>

      <CardBody className="mb-[-22px] ">
        <Typography variant="h5" color="blue-gray" className="mb-2">
        WorkThere – Co-Working Space 
        </Typography>
        <Typography>
        With thousands of office space listings globally and a team of global market experts. From plug and play co-working spaces, to large self-contained private offices.
         
         </Typography>
      </CardBody>

      <div className="group mb-[-2px] ml-5  inline-flex flex-wrap items-center  gap-7"> 

            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-wordpress"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-css3"></i>
            </span>
         
        </div>
        <CardFooter className="mt-[-16px]">
       <a href="https://www.workthere.com/" target="_blank"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
</div>
 {/* End  Portfolio 6  */}

  {/* Start Portfolio 7  */}
<div className="p-1">
  
  
 
<Card className="mb-[30px]  p-[4px] mt-0 w-90">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://ik.imagekit.io/hickterapp/Hickter.com/Portfolio/ah.webp?updatedAt=1714328953484"
          alt="Asset Homes – Real estate Home 
          "
        />
      </CardHeader>

      <CardBody className="mb-[-22px] ">
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Asset Homes – Real estate Home 
 
        </Typography>
        <Typography>
        We are one of Kerala’s premier home builders, having completed several projects on time and within budget.Get all deals of property at best rate possible for you. 
         
         </Typography>
      </CardBody>

      <div className="group mb-[-2px] ml-5  inline-flex flex-wrap items-center  gap-7"> 


   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-laravel"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-css3"></i>
            </span>
         
        </div>
        <CardFooter className="mt-[-16px]">
       <a href="https://www.assethomes.in/" target="_blank"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
</div>
 {/* End  Portfolio 7 */}


  {/* Start Portfolio 8  */}
  <div className="p-1">
   
  <Card className="mb-[30px]  p-[4px] mt-0 w-90">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://ik.imagekit.io/hickterapp/Hickter.com/Portfolio/jg'.webp?updatedAt=1714328983651"
          alt="Jersey Group – Real-estate Construction

          "
        />
      </CardHeader>

      <CardBody className="mb-[-22px] ">
        <Typography variant="h4" color="blue-gray" className="mb-2">
        Jersey Group   
        </Typography>
        <Typography>
        Jersey Group is Real-estate Construction one amongst the foremost group offering world leading MEP products & services, firmly established in Qatar  .
         </Typography>
      </CardBody>

      <div className="group mb-[-2px] ml-5  inline-flex flex-wrap items-center  gap-7"> 

   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-react"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-wordpress"></i>
            </span>
         
        </div>
        <CardFooter className="mt-[-16px]">
       <a href="https://www.jerseygroup.com/" target="_blank"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
</div>
 {/* End  Portfolio 8 */}


</Carousel>

  </section>

    
   </>
  )
}
  