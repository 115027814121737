
/* eslint-disable no-unused-vars */
import React from 'react' 
import {
    CardBody,
   Typography,
  Button, 
} from "@material-tailwind/react";
 import { useState, useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'font-awesome/css/font-awesome.min.css'; 

import { IconButton } from "@material-tailwind/react";
import { ButtonGroup } from "@material-tailwind/react";


   
export default function Digital_marketing_single() {
  const [size, setSize] = React.useState(null);
 
  const handleOpen = (value) => setSize(value);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4300);
  }, []);
  return (
   <>
    

   <div className="px-4 py-3 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-6 lg:py-15">
      <div className="grid gap-10 lg:grid-cols-2">
        <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
         
          <div className="max-w-xl mb-1">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Digital Marketing  
            <br></br>  <span className="text-green-500">
              At Hickter
              </span>
            </h2>
            <Typography>
        ✔ Now Grow Your Business Sales & Revenue By Paid Ads and Marketing to reach Potential Customers By Medium you want. .  
        </Typography> 
          </div>
           
          <CardBody className="bg-white" > 
          <Typography
          variant="h1"
          color="black"
          className="mt-0 flex justify-center gap-1 text-7xl font-normal"><span className="mb-0 text-orange-400 font-bold self-end text-[14px]"> Start <br></br>From  </span> 
          <span className="mt-0 text-[32px]">₹5,000 </span>{""}
          <span className="self-end text-[14px]"> / Month </span>  
        </Typography> <br></br>
       
        <Typography>
        Get These Services 
        </Typography>
 
        <Typography className="text-left">
        <div className="flex gap-1">
 
<div className="bg-blue-200 auto p-2 mt-1 mb-1 text-center text-black rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="ml-3 text-center p-1 h-12 w-15"
          src="https://ik.imagekit.io/hickterapp/Hickter.com/Icons/seo.png?updatedAt=1714376384388"
          alt=""
        /> <p className="text-[13px]" > SEO Marketing  </p>
      </div> 

<div className="bg-blue-200 auto p-2 mt-1 mb-1 text-center text-black rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="text-center p-1 h-12 w-15"
          src="https://ik.imagekit.io/hickterapp/Hickter.com/Icons/ads.png?updatedAt=1714376384752"
          alt=""
        /> <p className="text-[13px]" > Social Ads   </p>
      </div> 

<div className="bg-blue-200 auto p-2 mt-1 mb-1 text-center text-black rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="text-center p-1 h-12 w-15"
          src="https://ik.imagekit.io/hickterapp/Hickter.com/Icons/meta.png?updatedAt=1714376384439"
          alt=""
        /> <p className="text-[13px]" > Meta Ads  </p>
      </div> 
 
      <div className="bg-blue-200 auto p-2 mt-1 mb-1 text-center text-black rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10" >
      <img className="text-center p-1 h-12 w-15"
          src="https://ik.imagekit.io/hickterapp/Hickter.com/Icons/googleads.png?updatedAt=1714376384213"
          alt=""
        /> 
        <p className="text-[13px]" > Google Ads </p>
      </div> 
 
</div>
  
        </Typography>
  
      </CardBody>
          <div>
          <ButtonGroup className="ml-[-1px] text-center flex items-center font-semibold transition-colors duration-200 bg-orange-500 text-white rounded hover:text-deep-purple-800 gap-1 p-2">
     
     <a href="/Digital_marketing__apply">  
       <Button className="ml-[-2px] text-center flex items-center font-semibold transition-colors duration-200 bg-blue-500 text-white rounded-none">  <span className="cursor-pointer rounded-full  ">
      <i style={{fontSize:18}} className="fa-solid fa-envelope ml-3 p-2"></i>
      </span>Submit Request </Button></a>

       
      <a  href='dmhickter.pdf' target="_blank" download="Digital Marketing Hickter" >  
      <Button className="ml-[10px] text-center flex items-center font-semibold transition-colors duration-200 bg-green-600 text-white rounded hover:text-deep-purple-800 gap-1 ">  
      <i style={{fontSize:18}} className="fa-solid fa-download ml-1 p-2"></i>
     Download Brochure </Button> </a>
      
</ButtonGroup>
 
          </div>

        </div>
        <div className="flex items-center justify-center -mx-4 lg:pl-8">
          <div className="flex flex-col items-end px-3">
            <img
              className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
              src="https://ik.imagekit.io/hickterapp/Hickter.com/Banners/3(1).webp?updatedAt=1714375884154?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
              alt=""
            />
            <img
              className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
              src="https://ik.imagekit.io/hickterapp/Hickter.com/Banners/1(1).webp?updatedAt=1714375883982?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
              alt=""
            />
          </div>
          <div className="px-3">
            <img
              className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
              src="https://ik.imagekit.io/hickterapp/Hickter.com/Banners/2(1).webp?updatedAt=1714375883788?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;w=500"
              alt=""
            /> 
          </div>
           
        </div>
      </div>
    </div>
    </>
  )
}
 