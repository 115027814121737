/* eslint-disable no-unused-vars */
import React from 'react'
import Footersection from '../views/Home/Footersection'
import Navbarsection from '../views/Home/Navbarsection'
 
 

export default function Themeskit() {
  return (
   <>
    <Navbarsection/>
 
    <section className="text-gray-600 body-font">
  <div className="container px-5 py-24 mx-auto">
    <div className="flex flex-wrap -m-4 text-center">
      <div className="p-4 sm:w-1/4 w-1/2 text-center">
      <div className="flex justify-center items-center">
      <img src="../assets/themefile/shopify.png" className='text-center' style={{height:80, width:80}}/> 
</div> 
        <h2 className="title-font font-medium text-sm text-1xl text-gray-900">Shopify ( Ecommerce)
        </h2>
        <p className="leading-relaxed">Comming Soon  
        </p>
      </div>
      <div className="p-4 sm:w-1/4 w-1/2 text-center">
      <div className="flex justify-center items-center">
      <img src="../assets/themefile/woo.png" className='text-center' style={{height:80, width:80}}/> 
</div> 
        <h2 className="title-font font-medium text-sm text-1xl text-gray-900">Wordpress ( WooCommerce )

        </h2>
        <p className="leading-relaxed">Comming Soon 
        </p>
      </div>


      <div className="p-4 sm:w-1/4 w-1/2 text-center">
      <div className="flex justify-center items-center">
      <img src="../assets/themefile/laravel.png" className='text-center' style={{height:80, width:80}}/> 
</div> 
        <h2 className="title-font font-medium text-sm text-gray-900">HTML + Laravel ( Ecommerce)

        </h2>
        <p className="leading-relaxed">Comming Soon 
        </p>
      </div>



      <div className="p-4 sm:w-1/4 w-1/2 text-center">
      <div className="flex justify-center items-center">
      <img src="../assets/themefile/react.png" className='text-center' style={{height:80, width:80}}/> 
</div> 
        <h2 className="title-font font-medium text-sm text-gray-900">React + Laravel ( Ecommerce )

        </h2>
        <p className="leading-relaxed">Comming Soon 
        </p>
      </div>


    </div>
  </div>
</section>

   <Footersection/>

  
   </>
  )
}
