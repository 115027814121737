/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../views/Home/Navbarsection'
 import App_development_single from './Single/App_development_single'
import Website_development_single from './Single/Website_development_single'
import Digital_marketing_single from './Single/Digital_marketing_single'
import Banner_ads_single from './Single/Banner_ads_single'
import Logo_design_single from './Single/logo_design_single'
import Video_ads_single from './Single/Video_ads_single'
import Footersection from '../views/Home/Footersection'

export default function AllServices() {
  return (
   <>
   <Navbarsection/> 
   <App_development_single/>
   <br></br>
   <Website_development_single/><br></br>
   <Digital_marketing_single/><br></br>
   <Banner_ads_single/><br></br>
   <Video_ads_single/><br></br>
   <Logo_design_single/><br></br>

 <Footersection/>
    </>
  )
}
